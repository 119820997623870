import React from 'react'
import { useState } from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly')
  return (
    <Section className="position-relative   ">
   
      <Section className="row  ">
        <Section className="col-lg-6 ">
          {tab==='monthly' && (
            <PricingTable 
              title='Standard'
              price='50'
              currency='$'
              timeline='monthly'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='https://t.me/CryptoJab_VIP_bot'
            />
          )}
      
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-6">
         {tab==='monthly' && (
            <PricingTable 
              title='Professional'
              price='400'
              currency='$'
              timeline='yearly'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='https://t.me/CryptoJab_VIP_bot'
            />
          )}
          {tab==='yearly' && (
            <PricingTable 
              title='Professional'
              price='399'
              currency='$'
              timeline='yearly'
              features={['Static responsive website', 'Video marketing', 'Keywords research', 'Facebook campaign', 'eCommerce solution', 'Google adword']}
              btnText='Purchase Now'
              btnLink='https://t.me/CryptoJab_VIP_bot'
            />
          )}
          <Spacing lg='25' md='25'/>
        </Section>
    
      </Section>
    </Section>
  )
}
