import React from 'react'
import Div from '../Div'
import './funfact.scss'
import Spacing from '../Spacing'

export default function FunFact({variant, title, subtitle, data}) {
  return (
    <Div className={variant ? `cs-funfact_wrap ${variant}`: 'cs-funfact_wrap'}>
      <Div className="cs-funfact_shape"  style={{backgroundImage: 'url(./images/funfact_shape_bg.svg)'}} />
      <Div className="cs-funfact_left">
        <Div className="cs-funfact_heading">
          <h2>{title}</h2>
          <p>Monthly report for <strong> SEPTEMBER 2023 | OCTOBER 2023 | NOVEMBER 2023 | DECEMBER 2023 | JANUARY 2024 </strong>
Compelling statistics delivring unparalled trading signals and services to our members  </p>
        </Div>
      </Div>
      <Div className="cs-funfact_right">
        <Div className="cs-funfacts">
        {data.map((item, index) => (
          <Div className="cs-funfact cs-style1" key={index}>
            <Div className="cs-funfact_number cs-primary_font cs-semi_bold cs-primary_color"><span/>{item.factNumber}</Div>
            <Div className="cs-funfact_text">
              <span className="cs-accent_color">+</span>
              <p>{item.title}</p>
            </Div>
          </Div>
          ))}
        </Div>
        <Spacing lg="50" md="80" />
        <a className='cs-btn style2'> <span>Download</span></a>
      </Div>
    </Div>
  )
}
