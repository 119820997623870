import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import FunFact2 from '../FunFact/FunFact2';
import ServiceList from '../ServiceList';
import Accordion from '../Accordion';
import PricingTableList from '../PricingTable/PricingTableList';

import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import CryptoBG from "../../images/crypto-bg.jpg";
import CtaBG from "../../images/cta_bg.jpeg";


export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [

    {
      title: 'Accuracy',
      factNumber: '82%',
    },
    {
      title: 'Signal Profit',
      factNumber: '429%',
    },
    {
      title: 'Signals Provided',
      factNumber: '158',
    },
    {
      title: '',
      factNumber: '',
    },
 
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Crypto Signals <br/> & In-depth Market Analysis"
        subtitle="In this group, you will not only receive high-quality trading signals for cryptocurrencies, but Long-term and short-term trades as well. We will also provide you with in-depth market analysis, trade strategies, and educational content to help you become a better trader overall."
        btnText="Get Started"
        btnLink="#service"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl={CryptoBG}
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      
      <Div className="container">
        <FunFact
          title="6 Month Analysis"
          subtitle="Analysis report for previous 6 months <bold> SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER, JANUARY <bold/> a summary of the performance of several business processes. Armed with powerful data visualizations, managers and team members use these reports to track progress and performance against their business objectives."
          data={funfaceData}
        />
      </Div>
      {/* End FunFact Section */}
      <div id="service"></div>
      {/* Start Service Section */}
      <Spacing id="service" lg="145" md="80" />
    
      <Div className="container ">
        <SectionHeading
          title="Our core services"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <Div className="container">
        <ServiceList/>
        </Div>
      </Div>

      <Spacing id="service" lg="145" md="80" />

      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our agile process focuses on the ability to adapt and respond to change. Successful traders
         view change as an opportunity, not a threat.
        </h2>
      
      </Div>
      <Spacing id="service" lg="145" md="80" />
      <Div className="  cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='FAQ’s' 
                subtitle='Some pre questions and answers'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div  className="container ">
 
      <Spacing lg="125" md="55" />
     


      <Div className="container">

      <Spacing lg="125" md="55" />
        <SectionHeading
          title='Providing best <br/>pricing for traders' 
          subtitle='Pricing & Packaging'
          variant="cs-style1 text-center"
        />
        <Spacing lg='85' md='40'/>
        <PricingTableList/>
      </Div>
    
        <Spacing lg="125" md="55" />
        </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      {/* <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div> */}
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      {/* <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity, not a threat.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div> */}
      {/* End Video Block Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      {/* <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="105" md="70" /> */}
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      {/* <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Apply For Meeting"
          btnText="ZOOM CALL"
          btnLink="/contact"
          bgSrc={CtaBG}
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
